<template>
	<div class="report-container">
		<div class="report-title-box">
			<div>待开发票</div>
			<div class="title-right">
				<span class="service">客服电话：</span>
				<span>{{ servicePhone }}</span>
			</div>
		</div>
		<div class="divider"></div>
		<div class="report-content">
			<div class="invoice-btn" v-show="total != 0">
				<el-button type="primary" :disabled="disabled" @click="goTogetherInvoice">合并开票</el-button>
			</div>
			<div v-if="total == 0" style="margin-top: 150px">
				<empty name="暂无数据"></empty>
			</div>
			<div class="report-box" v-show="total != 0">
				<el-table :data="tableData" ref="tableData" style="width: 100%" header-cell-class-name="table_header" height="600px" @selection-change="handleSelectionChange">
					<el-table-column type="selection" width="55" align="center"></el-table-column>
					<el-table-column type="index" label="序号" width="80"></el-table-column>
					<el-table-column prop="orderCode" label="订单编号" :show-overflow-tooltip="true"></el-table-column>
					<el-table-column prop="commodityName" label="购买详情" :show-overflow-tooltip="true"></el-table-column>
					<el-table-column prop="" label="支付金额（元）">
						<template slot-scope="scope">￥{{ calcNum(scope.row.paymentAmount) }}</template>
					</el-table-column>
					<el-table-column prop="paymentDate" label="支付时间" width="180"></el-table-column>
					<el-table-column prop="" label="电子发票">
						<template slot-scope="scope">
							<span class="view-style" @click="goInvoice(scope.row)">开票</span>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<div class="pagination-box">
				<pagination ref="pagination" :total="total" @change="paginChange" v-show="total != 0"></pagination>
			</div>
			<!-- 开票弹窗 -->
			<div class="wai-invo">
				<Modal :visible.sync="visible" @reset="resetFields" class="dialog-style">
					<div slot="title" style="padding-left: 20px; border-left: 4px solid #fff">
						电子发票信息
					</div>
					<el-form :model="dataPO" :rules="rules" ref="form" label-width="100px" class="demo-ruleForm">
						<el-row>
							<el-col :span="24">
								<p class="mti-prompt">
									<i class="el-icon-info"></i>
									电子发票与纸质发票具有同等法律效力，可支持报销入账；同时根据增值税管理办法要求，如需为企业开具增值税发票，需提供纳税人识别号或统一社会信用代码，否则该发票无法作为税收凭证。
								</p>
							</el-col>
							<el-col :span="24">
								<el-form-item label="订单编号" prop="orderCode" class="span-item title-item txt-style">
									<span>{{ dataPO.orderCode }}</span>
								</el-form-item>
							</el-col>
							<el-col :span="24">
								<el-form-item label="订单金额" prop="paymentAmount" class="span-item title-item">
									<span class="money-style">￥{{ calcNum(dataPO.paymentAmount) }}</span>
								</el-form-item>
							</el-col>
							<el-col :span="24">
								<el-form-item label="发票类型" prop="invoiceType" class="title-item span-item">
									<span v-for="(item, index) in invoiceTypeList" :key="index" :label="item.value" :value="item.value">{{ item.label }}</span>
									<!-- <el-radio-group v-model="dataPO.invoiceType">
                    <el-radio
                      v-for="(item, index) in invoiceTypeList"
                      :key="index"
                      :label="item.value"
                      :value="item.value"
                      >{{ item.label }}</el-radio>
                  </el-radio-group> -->
								</el-form-item>
							</el-col>
							<el-col :span="24">
								<el-form-item label="主体类型" prop="subjectType" class="title-item span-item">
									<el-radio-group v-model="dataPO.subjectType" @change="handleChangeSubjectType">
										<el-radio v-for="item in subjectTypeList" :key="item.value" :label="item.value" :value="item.value">{{ item.label }}</el-radio>
									</el-radio-group>
								</el-form-item>
							</el-col>
							<el-col :span="24">
								<el-form-item label="发票内容" prop="invoiceContent" class="title-item span-item">
									<el-radio-group v-model="dataPO.invoiceContent">
										<el-radio v-for="(item, index) in invoiceContentList" :key="index" :label="item.value" :value="item.value">{{ item.label }}</el-radio>
									</el-radio-group>
								</el-form-item>
							</el-col>
							<el-col :span="24" v-show="subTypeFlag == 1">
								<el-form-item label="单位税号" prop="unitTaxNo" class="title-item span-item">
									<el-input v-model.trim="dataPO.unitTaxNo" placeholder="请输入单位税号"></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="24" v-show="subTypeFlag == 1">
								<el-form-item label="单位名称" prop="corpname" class="title-item span-item">
									<el-input v-model.trim="dataPO.corpname" placeholder="请输入单位名称" maxlength="200"></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="24" v-show="subTypeFlag == 2">
								<el-form-item label="个人抬头" prop="peopleLookUp" class="title-item span-item">
									<el-input v-model.trim="dataPO.peopleLookUp" placeholder="请输入个人抬头" maxlength="200"></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="24">
								<el-form-item label="邮箱" prop="mailbox" class="title-item span-item">
									<el-input v-model.trim="dataPO.mailbox" placeholder="请输入邮箱"></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="24" v-show="subTypeFlag == 1">
								<el-form-item label="开户银行" prop="bankName" class="title-item span-item">
									<el-input v-model.trim="dataPO.bankName" placeholder="请输入开户银行" maxlength="200"></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="24" v-show="subTypeFlag == 1">
								<el-form-item label="银行账户" prop="bankAccount" class="title-item span-item">
									<el-input v-model.trim="dataPO.bankAccount" placeholder="请输入银行账户" maxlength="200"></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="24" v-show="subTypeFlag == 1">
								<el-form-item label="企业地址" prop="companyAddress" class="title-item span-item">
									<el-input v-model.trim="dataPO.companyAddress" placeholder="请输入企业地址" maxlength="200"></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="24" v-show="subTypeFlag == 1">
								<el-form-item label="联系电话" prop="contactNumber" class="title-item span-item">
									<el-input v-model.trim="dataPO.contactNumber" placeholder="请输入联系电话"></el-input>
								</el-form-item>
							</el-col>

							<el-col>
								<el-form-item class="title-item btn-item">
									<el-button type="primary" @click="submitForm('form')">提交</el-button>
								</el-form-item>
							</el-col>
						</el-row>
					</el-form>
				</Modal>
			</div>
		</div>
	</div>
</template>

<script>
import Pagination from "@/components/Pagination";
import Empty from "@/components/Empty";
import Modal from "@/components/Modal";
import {validateTax} from "@/utils/validate.js";
import {mapGetters} from "vuex";
export default {
	components: {
		Pagination,
		Empty,
		Modal,
	},
	data() {
		// 手机固话校验
		const validatePhone = (rule, value, callback) => {
			const moblie = "^((13[0-9])|(14[5,7])|(15[0-3,5-9])|(17[0,3,5-9])|(18[0-9])|166|198|199|191|195|193|(147))\\d{8}$";
			const tel = /^(\d{3,4}-)?\d{7,8}$/;
			if (!value) {
				callback();
			} else if (!new RegExp(moblie).test(value) && !tel.test(value) && value !== "") {
				callback(new Error("请输入格式正确的联系方式"));
			} else {
				callback();
			}
		};
		// 邮箱校验
		const validateEmail = (rule, value, callback) => {
			if (!/^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/.test(value)) {
				callback(new Error("请输入格式正确的邮箱"));
			} else {
				callback();
			}
		};
		// 银行账户  数字输入 validateAccount
		const validateAccount = (rule, value, callback) => {
			const account = /^\d+$/;
			if (!value) {
				callback();
			} else if (!account.test(value) && value !== "") {
				callback(new Error("请输入格式正确的银行账户"));
			} else {
				callback();
			}
		};
		// 税号校验
		// const validateTax = (rule, value, callback) => {
		//   const tax = /^[A-Z0-9]{15}$|^[A-Z0-9]{17}$|^[A-Z0-9]{18}$|^[A-Z0-9]{20}$/;
		//   if (!tax.test(value) && value !== "") {
		//     callback(new Error("请输入格式正确的单位税号"));
		//   } else {
		//     callback();
		//   }
		// };

		return {
			total: 0,
			tableData: [],
			disabled: true,
			selectionList: [],
			visible: false,
			ids: [],
			orderCodes: [],
			moneys: [],
			money: 0,
			dataPO: {},
			invoiceTypeList: [
				{
					label: "增值税电子普通发票",
					value: "82",
				},
				// {
				//   label: "增值税专用发票",
				//   value: "81",
				// },
			],
			subjectTypeList: [
				{
					label: "企事业单位",
					value: "60461002",
				},
				{
					label: "个人",
					value: "60461001",
				},
			],
			subTypeFlag: 1,
			invoiceContentList: [
				{
					label: "信息技术服务",
					value: "60651001",
				},
				// {
				//   label: "商品明细",
				//   value: "60651002",
				// },
			],
			rules: {
				corpname: [{required: true, message: "请输入单位名称", trigger: "blur"}],
				unitTaxNo: [
					{required: true, message: "请输入单位税号", trigger: "blur"},
					{
						validator: validateTax,
					},
				],
				// invoiceType: [
				//   { required: true, message: "请选择您的发票类型", trigger: "change" },
				// ],
				subjectType: [{required: true, message: "请选择您的主体类型", trigger: "change"}],
				invoiceContent: [{required: true, message: "请选择您的发票内容", trigger: "change"}],
				peopleLookUp: [{required: true, message: "请输入个人抬头", trigger: "blur"}],
				mailbox: [
					{required: true, message: "请输入邮箱", trigger: "blur"},
					{
						validator: validateEmail,
					},
				],
				contactNumber: [
					{
						trigger: "blur",
					},
					{
						validator: validatePhone,
					},
				],
				bankAccount: [
					{
						trigger: "blur",
					},
					{
						validator: validateAccount,
					},
				],
			},
			params: {
				pageNum: 1,
				pageSize: 10,
			},
			row: null,
		};
	},
	computed: {
		...mapGetters(["servicePhone"]),
	},
	created() {},
	mounted() {
		this.pageConsumerFinance();
	},
	methods: {
		// 获取列表
		pageConsumerFinance() {
			this.$api.personalCenter.pageConsumerFinance({...this.params, type: 1}).then(res => {
				this.tableData = res.rows;
				this.total = res.total;
			});
		},
		// 保留两位小数方法
		calcNum(val) {
			return Number(val).toFixed(2);
		},
		// 切换分页
		paginChange(page, pageSize) {
			this.params.pageNum = page;
			this.params.pageSize = pageSize;
			this.pageConsumerFinance();
		},
		// 开票按钮
		goInvoice(row) {
			this.row = row;
			this.visible = true;
			this.subTypeFlag = 1;
			let data = JSON.parse(JSON.stringify(row));
			this.$api.personalCenter
				.getHistoryInfo()
				.then(res => {
					this.dataPO = res.data;
					this.dataPO.paymentAmount = data.paymentAmount;
					this.dataPO.orderCode = data.orderCode;
					this.dataPO.financeIds = [data.invoiceId];
					if (this.dataPO.subjectType == "60461001") {
						this.subTypeFlag = 2;
						this.rules["unitTaxNo"] = [];
						this.rules["corpname"] = [];
						this.$refs.form.clearValidate("unitTaxNo");
						this.$refs.form.clearValidate("corpname");
						this.rules["peopleLookUp"] = [{required: true, message: "请输入个人抬头", trigger: "blur"}];
						this.$forceUpdate();
					} else if (this.dataPO.subjectType == "60461002") {
						this.subTypeFlag = 1;
						this.rules["unitTaxNo"] = [
							{required: true, message: "请输入单位税号", trigger: "blur"},
							{
								validator: validateTax,
							},
						];
						this.rules["corpname"] = [{required: true, message: "请输入单位名称", trigger: "blur"}];
						this.rules["peopleLookUp"] = [];
						this.$refs.form.clearValidate("peopleLookUp");
						this.$forceUpdate();
					}
					this.$forceUpdate();
				})
				.catch(msg => {
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				});

			this.$forceUpdate();
		},
		// 合并开票
		goTogetherInvoice() {
			this.visible = true;
			this.subTypeFlag = 1;
			this.moneys.forEach(items => {
				this.money += items;
			});
			this.$api.personalCenter
				.getHistoryInfo()
				.then(res => {
					this.dataPO = res.data;
					this.dataPO.paymentAmount = this.money;
					this.dataPO.orderCode = this.orderCodes.join(",");
					this.dataPO.financeIds = this.ids;
					if (this.dataPO.subjectType == "60461001") {
						this.subTypeFlag = 2;
						this.$refs.form.clearValidate("unitTaxNo");
						this.$refs.form.clearValidate("corpname");
						this.rules["unitTaxNo"] = [];
						this.rules["corpname"] = [];
						this.rules["peopleLookUp"] = [{required: true, message: "请输入个人抬头", trigger: "blur"}];
						this.$forceUpdate();
					} else if (this.dataPO.subjectType == "60461002") {
						this.subTypeFlag = 1;
						this.rules["unitTaxNo"] = [
							{required: true, message: "请输入单位税号", trigger: "blur"},
							{
								validator: validateTax,
							},
						];
						this.rules["corpname"] = [{required: true, message: "请输入单位名称", trigger: "blur"}];
						this.$refs.form.clearValidate("peopleLookUp");
						this.rules["peopleLookUp"] = [];
						this.$forceUpdate();
					}
					console.log(this.rules);
					this.$forceUpdate();
				})
				.catch(msg => {
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				});

			this.$forceUpdate();
		},
		// 选择框事件
		handleSelectionChange(val) {
			this.ids = [];
			this.moneys = [];
			this.orderCodes = [];
			this.selectionList = this.$refs.tableData.selection;
			if (this.selectionList.length > 0 && this.selectionList.length <= 10) {
				this.disabled = false;
			} else if (this.selectionList.length > 10) {
				this.disabled = true;
				this.$message.error("最多支持10个订单合并开票！");
			} else if (this.selectionList.length <= 0) {
				this.disabled = true;
			}
			this.selectionList.forEach(item => {
				this.ids.push(item.invoiceId);
				this.moneys.push(item.paymentAmount);
				this.orderCodes.push(item.orderCode);
			});
			this.money = 0;

			return this.selectionList;
		},
		// 切换主体事件
		handleChangeSubjectType(val) {
			if (val == "60461001") {
				this.subTypeFlag = 2;
				this.$refs.form.clearValidate("unitTaxNo");
				this.$refs.form.clearValidate("corpname");
				this.rules["unitTaxNo"] = [];
				this.rules["corpname"] = [];
				this.rules["peopleLookUp"] = [{required: true, message: "请输入个人抬头", trigger: "blur"}];
				this.$forceUpdate();
			} else if (val == "60461002") {
				this.subTypeFlag = 1;
				this.rules["unitTaxNo"] = [
					{required: true, message: "请输入单位税号", trigger: "blur"},
					{
						validator: validateTax,
					},
				];
				this.rules["corpname"] = [{required: true, message: "请输入单位名称", trigger: "blur"}];
				this.rules["peopleLookUp"] = [];
				this.$refs.form.clearValidate("peopleLookUp");
				this.$forceUpdate();
			}
			this.$forceUpdate();
		},
		// 关闭弹窗时重置表单
		resetFields() {
			this.$refs.form.resetFields();
			this.dataPO = {};
			this.subTypeFlag = 1;
			this.money = 0;
			// this.moneys = [];
		},
		// 表单提交事件
		submitForm(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					const loading = this.$loading({
						lock: true,
						text: "加载中...",
						spinner: "el-icon-loading",
						background: "rgba(255, 255, 255, 0.3)",
						fullscreen: false,
					});
					if (this.row.commodityName == "普通会员" ) {
						this.row.commodityName = this.row.commodityName + "服务";
					}else if (this.row.commodityName == "VIP") {
						this.row.commodityName = this.row.commodityName + "会员服务";
          }
					this.$api.personalCenter
						.makeInvoice({...this.dataPO, invoiceType: "82", consumerInvoiceOrderInvoiceList: [this.row]})
						.then(res => {
							this.visible = false;
							this.money = 0;
							this.moneys = [];

							this.$message.success("提交成功");
							this.pageConsumerFinance();
						})
						.catch(err => {
							this.$message.error("提交失败");
						})
						.finally(() => {
							loading.close();
						});
				} else {
					// 表单回到顶部
					document.querySelector(".demo-ruleForm").scrollTop = 200;
					return false;
				}
			});
		},
	},
};
</script>

<style lang="less" scoped>
@import "./index.less";
</style>
